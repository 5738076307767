.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, ubuntu, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: astriangle;
    src: url(./utils/fonts/AstriangleDemoRegular.ttf);
}

@font-face {
    font-family: ubuntu;
    src: url(./utils/fonts/Ubuntu-Regular.ttf);
}

.astriangle {
    font-family: astriangle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}
